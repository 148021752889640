import { template as template_7ec0dbe93c7f43b5ab4b6dc58b46e91e } from "@ember/template-compiler";
const FKControlMenuContainer = template_7ec0dbe93c7f43b5ab4b6dc58b46e91e(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
