import { template as template_f299652a028948809273d9be52667ae8 } from "@ember/template-compiler";
const WelcomeHeader = template_f299652a028948809273d9be52667ae8(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
